import React from 'react'
import CardList from '@teComponents/ProductCard/CardList'
import { Layout, Stack } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import { Box } from 'theme-ui'

//SAMPLE DATA
// const product = {
//   variant: 'interactive',
//   // aside: PropTypes.bool,
//   // columns: PropTypes.array,
//   mediaType: 'image',
//   imageVariant: 'hero',
//   loading: 'auto',
//   title: 'ProductName',
//   slug: '/products',
//   // link: PropTypes.string,
//   category: {
//     name: 'Category',
//     // color: PropTypes.string,
//     // slug: PropTypes.string,
//     // icon: PropTypes.string
//   },
//   author: {
//     name: '$11',
//     // slug: PropTypes.string,
//     // thumbnail: PropTypes.object
//   },
//   // date: PropTypes.string,
//   // timeToRead: PropTypes.number,
//   excerpt: 'Product DEscription',
//   // thumbnail: data,
//   thumbnailText: 'thumbnail text'
// }

// this is dummy data for this test page, and can be deleted.
const kitProducts = [
  {
    key: 'K-AMO1'
  },
  {
    key: 'K-ANT1'
  },
  {
    key: 'K-ACT1'
  },
  {
    key: '27MHz Field Strength Meter'
  },
  {
    key: 'LED TESTER'
  },
  {
    key: 'CONTINUITY TESTER'
  },
  {
    key: 'LOGIC PROBE with PULSER - slimline'
  }
]

const SvgWave = () => (
  <svg
    style={styles.wave}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1440 320'
  >
    <path
      fill='#667eea'
      fill-opacity='1'
      d='M0,128L40,112C80,96,160,64,240,90.7C320,117,400,203,480,218.7C560,235,640,181,720,176C800,171,880,213,960,202.7C1040,192,1120,128,1200,106.7C1280,85,1360,107,1400,117.3L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'
    ></path>
  </svg>
)

const styles = {
  header: {
    padding: 1
  },
  container: {
    justifyContent: `center`,
    alignItems: `center`,
    alignContent: `center`
  },
  cards: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `flex-start`
  },
  wave: {
    position: `absolute`,
    zIndex: `-1`,
    bottom: 0,
    left: 0
  }
}

const Products = props => (
  <Layout {...props}>
    <Stack>
      <PageTitle header='Products' />
    </Stack>
    <Divider />
    <Stack>
      <Box sx={styles.container}>
        <CardList
          type='list'
          products={kitProducts}
          {...props}
          variant='interactive'
        />
        <SvgWave /> {/* TO FIX */}
      </Box>
    </Stack>
  </Layout>
)

export default Products
